window.Hubbado = window.Hubbado || {};

class HyerhubBanner {
  constructor() {
    this.container = document.querySelector('.top-banners');
    this.template = this.container.querySelector('.template').innerHTML;
    if (localStorage.getItem('hyerhub-banner')) {
      return
    }
    this._bind();
  }

  message(msg) {
    const message = document.createElement('div');
    message.classList.add('flash', 'hyerhub-banner', 'warning');
    message.innerHTML = this.template;
    const messageContainer = message.querySelector('.message');
    messageContainer.innerHTML = msg;
    this.container.append(message);
  }

  _bind() {
    const cta_url = 'https://hyerhub.com?utm_source=hubbado&utm_medium=banner&utm_campaign=hyerhub-launch';
    this.message('<content><strong>Hubbado is changing: our recruitment services are moving to Hyerhub.com</strong> <p>For automated recruitment workflow software, watch this space!</p></content><a class="btn secondary" href="' + cta_url + '">Visit Hyerhub.com</a>');
    this.container.addEventListener('click', (e) => {
      const closeButton = e.target.closest('.close-button');
      if (!closeButton) return;
      localStorage.setItem('hyerhub-banner', true);
      const message = closeButton.closest('.flash');
      message.remove();
      window.dispatchEvent(new Event('resize'));
    });
  }
}

document.addEventListener("DOMContentLoaded", () => {
  Hubbado.HyerhubBanner = new HyerhubBanner();
});
